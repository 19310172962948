import React from 'react';
import Block from '@components/Block/Block';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
  greyColor, greenColor, blueColor, orangeColor, Typography,
} from '@main/lib';

const styles = () => ({
  block: {
    backgroundColor: 'white',
    zIndex: '100',
    padding: '0px',
    width: '220px',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',

  },
  green: {
    color: greenColor[100],
  },
  yellow: {
    color: orangeColor[100],
  },
  blue: {
    color: blueColor[100],
  },
  key: {
    color: greyColor[50],
  },
  value: {

  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  root: {
    padding: '10px',
  },
  padded: {
    padding: '8px',
  },
});
const ChartTooltip = ({
  className, classes, date, data, bottomData,
}) => {
  const row = (key, value, valueClassName) => (
    <div className={classes.row}>
      <div className={classes.key}><Typography variant="detailed">{key}</Typography></div>
      <div className={clsx(classes.value)}>
        <Typography variant="detailed" className={valueClassName} bold>
          {value}
          {' '}
          ₸
        </Typography>
      </div>
    </div>
  );
  return (
    <div className={classes.root}>
      <Block className={clsx(className, classes.block)}>
        <div className={classes.padded}>
          {date}
        </div>
        <hr />
        <div className={classes.padded}>
          {data?.map((dataPiece) => row(dataPiece[0], dataPiece[1], classes[dataPiece[2]]))}
        </div>
        { bottomData && (
        <div>
          <hr />
          <div className={classes.padded}>
            {row(bottomData[0], bottomData[1])}
          </div>
        </div>
        )}
      </Block>
    </div>
  );
};

export default withStyles(styles)(ChartTooltip);
