import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Layout from '@components/Layout';
import Error from '@pages/error/Error';
import vkRedirectComponent from '@pages/channels/components/vk/VkRedirectComponent';
import fbRedirect from '@pages/channels/components/facebook/redirect';
import vkRedirectGroup from '@pages/channels/components/vk/VkRedirectGroup';
// import Policy from '@pages/policy/Policy';
import { loginSuccess } from '@store/actions';
import SettingsCheck from '@components/SettingsCheck';
import { useTheme } from '@material-ui/styles';
import PasswordRestoration from '@pages/entrance/components/PasswordRestoration';
import { Typography } from '@main/lib';
import Entrance from '@pages/entrance';
import Alert from '@components/Alert';
import Offers from './pages/offers';

const user = JSON.parse(localStorage.getItem('user'));

const PrivateRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      localStorage.getItem('user') ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    )}
  />
);

const style = () => ({
  root: {
  },
});

const PublicRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (localStorage.getItem('user') && localStorage.getItem('scopes') ? (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    ) : (
      React.createElement(component, props)
    ))}
  />
);

const App = ({ loginSuccess, isAuthenticated }) => {
  const theme = useTheme();
  useEffect(() => {
    if (user) {
      loginSuccess({
        username: user.user.username,
        userEmail: user.user.user_mail,
        userPicture: user.user.picture,
      });
    }
  }, []);

  const allOffers = localStorage.getItem('allOffers') === 'true';

  return (
    <div style={{ height: '100vh' }}>
      <Typography>
        <Alert
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
        />
        <SettingsCheck>
          <MuiThemeProvider theme={theme}>
            <Switch>
              <Route path="/redirect/vk/user" component={vkRedirectComponent} />
              <Route path="/redirect/fb/user" component={fbRedirect} />
              <Route path="/redirect/vk/group" component={vkRedirectGroup} />
              <Route
                exact
                path="/"
                render={(props) => {
                  console.log(user);
                  if (!isAuthenticated) {
                    return <Redirect to="/login" />;
                  }
                  return <Redirect to="/app/offers/all-offers" />;
                }}
              />

              <Route
                path="/app/offers/all-offers"
                render={(props) => {
                  if (isAuthenticated) {
                    if (!allOffers) localStorage.setItem('allOffers', true);
                    return <PrivateRoute path="/app" component={Layout} />;
                  } if (!isAuthenticated && allOffers) {
                    return <Redirect to="/login" />;
                  }
                  return <Offers />;
                }}
              />

              <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
              <PrivateRoute path="/app" component={Layout} />
              <PublicRoute path="/login" component={Entrance} />
              <PublicRoute path="/reset_password" component={PasswordRestoration} />
              <PublicRoute path="/register" component={Entrance} />
              {/*<Route path="/policy" component={Policy} />*/}
              <Route component={Error} />
            </Switch>
          </MuiThemeProvider>
        </SettingsCheck>
      </Typography>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loginSuccess,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
