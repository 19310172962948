import {
  ACTION_TYPE,
  ACTIONS,
  ACTIONS_COUNT,
  ACTIVE,
  ACTIVE_USERS,
  ADD_CHANNEL,
  ADD_COLUMNS,
  ADD_FILTER,
  ADD_GROUPING,
  ADDITIONAL_PARAMETERS,
  ALL_CHANNELS,
  ALL_GOALS,
  AMOUNT,
  ANALYTICS,
  APPROVED_CONVERSIONS,
  AUDIENCE_CHURN,
  AUTHORIZATION,
  AVAILABLE_CHANNELS,
  AVAILABLE_FOR_WITHDRAWAL,
  BALANCE,
  BILL,
  BILLING_METHOD,
  BY_DAY,
  BY_INTERACTION,
  CAMPAIGN_NAME,
  CHANNEL,
  CHANNELS,
  CHANNELS_ADD_BUTTON_TOOLTIP,
  CHANNELS_ANALYTICS_TOOLTIP,
  CHANNELS_DIALOGS_TOOLTIP,
  CHANNELS_EXPANSION_LIST_TOOLTIP,
  CHANNELS_LINKS_TOOLTIP,
  CHURN,
  CHURN_COUNT,
  CITIES,
  CLICK_TO_COPY,
  CLOSED,
  COMMENT,
  CONNECT,
  CONNECTING_TELEGRAM_BOT,
  CONNECTING_VIBER_BOT,
  COPIED,
  COUNTRY,
  CR_CLICKED_LINKS,
  CR_GIVEN_LINKS,
  CREATED,
  CURRENT_MONTH,
  CUSTOM_DATES,
  CZECH_REPUBLIC,
  DATE,
  DAY,
  DESCRIPTION,
  DIALOG_COST,
  DIALOGS,
  DIALOGS_COUNT,
  DIRECT_LINK,
  DOWNLOAD,
  DOWNLOAD_REPORT,
  EARNINGS_PER_CONVERSION,
  EARNINGS_PER_DIALOG,
  ENTER_THE_AMOUNT_TO_WITHDRAW,
  ESTIMATE_DATE,
  EXTRA_FIELD,
  FINANCES,
  GEOGRAPHY,
  GERMANY,
  GOALS_AND_ACTIONS,
  GROUPING_BY_DAYS,
  GROUPING_BY_HOURS,
  GROUPING_BY_MONTHS,
  GROUPING_BY_YEARS,
  GROUPS_VK,
  HOLD,
  HOLD_CONVERSIONS,
  HOUR,
  HTML_CODE,
  IN_PROGRESS,
  INCOME,
  ITALY,
  KAZAKHSTAN,
  LAST_14_DAYS,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_MONTH,
  LEADS,
  LINKS,
  MAIN_PARAMETERS,
  MANAGE,
  MONTH,
  NAME,
  NEW_REQUEST,
  NEW_USERS,
  NEWEST_FIRST,
  NO_DATA,
  OFFER,
  OFFER_CONNECT_TOOLTIP,
  OFFERS,
  OLDEST_FIRST,
  ON_HOLD,
  ORDER_A_PAYMENT,
  PAGES_FACEBOOK,
  PAUSE,
  PAYMENTS_AMOUNT,
  PAYOUT_HISTORY,
  PLATFORM,
  POLAND,
  PREFERRED_CITIES,
  RATING_NOT_YET_AVAILABLE,
  REJECTED_CONVERSIONS,
  REMOVE,
  REPORTS,
  REQUEST,
  RESET,
  RUSSIA,
  SETTINGS,
  SIGN_IN,
  SOURCE,
  SPAIN,
  START,
  STATUS,
  SWITCH_PROFILE,
  TODAY,
  TOKEN,
  TOTAL,
  TOTAL_ON_ACCOUNT,
  TRACKING_LINK,
  TRAFFIC_TYPE,
  UKRAINE,
  UNWANTED_CITIES,
  USA,
  USERS,
  WHICH_CHANNEL_TO_CONNECT,
  YEAR,
  TOOLS,
  YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED,
  YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED,
  APPEARANCE,
  THE_AMOUNT_OF_MONEY,
  ERROR_LOADING_PAYMENT_METHODS,
  MONEY_THAT_IS_CURRENTLY_AVAILABLE_FOR_WITHDRAWAL,
  PAYMENTS_YOU_ORDERED,
  WELCOME_TO_FINANCE,
  ON_THIS_PAGE_YOU_CAN_WITHDRAW_YOUR_FUNDS,
  MONEY_THAT_IS_CURRENTLY_NOT_AVAILABLE_FOR_WITHDRAWAL,
  ORDER_A_PAYOUT,
  YIELD_CHART,
  AVAILABLE,
  INCOME_PER_DAY,
  CONNECTED_CHANNELS,
  CONNECTED_MESSENGERS_TO_OFFERS,
  THE_AUDIENCE,
  THE_AUDIENCE_DESC,
  LTV,
  TOTAL_INCOME,
  LTV_DESC,
  INCOME_PER_DIALOG,
  INCOME_PER_DIALOG_DESCRIPTION,
  DELETE_ERROR,
  OFFER_INFO,
  COST_PER_ACTION,
  COST_PER_ACTION_DESC,
  REVENUE_SHARE_DESC,
  REVENUE_SHARE,
  PENDING,
  EXIT,
  ALL_OFFERS,
  MY_OFFERS,
  CHOOSE_THE_RIGHT_MESSENGER,
  CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL,
  CONNECT_CHANNEL,
  YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED,
  ON_THE_CHANNELS_PAGE_,
  GO_TO_CHANNELS_PAGE,
  CONNECT_ANOTHER_CHANNEL,
  CONNECTING_CHANNELS,
  CONVERSION,
  OFFER_STAT_CONVERSION_DESC,
  OFFER_STAT_INCOME_DESC,
  ALL,
  FAVORITES,
  LOGO,
  MORE,
  PLACE_DELETED,
  INCOME_DESC,
  ON_THIS_PAGE_YOU_CAN_SEE_THE_TERMS_,
  SEARCH,
  PLACE,
  PLACE_STOPPED,
  PLACE_ACTIVE,
  STOPPED_ERROR,
  ACTIVATION_ERROR,
  WANT_DELETE_PACE,
  CANCEL,
  APPROVE,
  ATTRITION,
  ERROR,
  BACK,
  PAYOUTS,
  CONNECT_OFFER,
  REGISTRATION,
  OFFER_DESC,
  MONETIZATION_OPTIONS,
  AVAILABLE_MESSENGERS,
  CLICK_PER_LINK_DESC,
  EVENTS,
  STATISTICS,
  DAILY_REPORT,
  TOUCH_REPORT,
  NEW_REPORT,
  SELECT_DATES,
  RESET_ALL,
  APPLY_FILTERS,
  FILTERS,
  CHOOSE_THE_FILTERS_,
  ERROR_DURING_FETCH_BALANCE,
  WAS_CREATED,
  IN_PROCESSING,
  COMPLETED,
  REJECTED,
  PAYMENT_HISTORY,
  REMOVING_A_HOLD,
  TERMS_AND_PROCEDURE_OF_PAYMENTS,
  ID,
  DATE_OF_CREATION,
  SUM,
  PAYOUT_METHOD,
  TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC,
  PAYMENT_TERM,
  COMMISSION_AMOUNT,
  WORKING_DAY,
  WORKING_DAYS,
  WORKING_DAYS_2,
  ZALEYCASH,
  GETUNIQ,
  WEBMONEY_WMZ,
  YANDEX_MONEY,
  SOLAR_STAFF,
  CAPITALIST,
  GOOGLE_ADWORDS,
  MYTARGET,
  YANDEX_DIRECT,
  BANK_CARD,
  BANK_TRANSFER,
  BANK_TRANSFER_WITH_EDI,
  BANK_TRANSFER_SELF_EMPLOYED,
  SUBSCRIBERS,
  SUBSCRIBERS_DESC,
  BANNED,
  COMPLETED_ALL_ACTIONS,
  CONVERSIONS,
  REVENUE_PER_CONVERSION,
  REVENUE,
  NUMBER_OF_FIRST_EVENT_CONVERSIONS,
  FIRST_EVENT_CONVERSION_PERCENTAGE,
  BY_HOURS,
  BY_MONTH,
  BY_YEAR,
  REPORT_BY_DAY,
  BY_OFFERS,
  BY_CHANNELS,
  CLICKS,
  APPROVED,
  EXIT_DATES_FROM_HOLD,
  NUMBER_OF_CONVERSIONS,
  TOTAL_L,
  HOLD_REMOVAL_SCHEDULE,
  HOLD_REMOVAL_SCHEDULE_,
  CAME_OUT_OF_HOLD,
  ERROR_WHILE_GETTING_LIST_OF_OFFERS,
  SELECTED,
  SELECTED_1,
  SELECTED_2,
  SELECTED_3,
  BY_OFFER,
  OFFERS_1,
  OFFERS_L,
  OFFER_L,
  BY_CHANNEL,
  CHANNEL_L,
  CHANNEL_L_1,
  CHANNEL_L_2,
  BY_PLATFORM,
  PLATFORM_L,
  PLATFORMS_L,
  PLATFORM_L_1,
  BY_UTM_MARKS,
  MARK_L,
  MARK_L_1,
  MARKS_L,
  COPY_LINK,
  LINK_COPIED,
  UTM_MARKS,
  UTM_MARKS_CREATE_DESC,
  DOES_NOT_HAVE_CONNECTED_OFFERS,
  DOES_NOT_HAVE_CONNECTED_OFFERS_DESC,
  GO_TO_OFFER_PAGE,
  EMPTY,
  EMPTY_FAVORITE_OFFERS,
  DOES_NOT_HAVE_HOLD,
  DOES_NOT_HAVE_HOLD_DESC,
  YOU_HAVE_NOT_MADE_ANY_PAYMENTS,
  YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC,
  ORDER_PAYMENT,
  NO_DATA_BY_FILTERS,
  NO_CONNECTED_CHANNELS,
  NO_CONNECTED_CHANNELS_DESC,
  HAVE_USER_WHIS_PHONE,
  HAVE_ACCOUNT_Q,
  ENTER,
  NAME_FIELD,
  NAME_PLACEHOLDER,
  PHONE_NUMBER,
  INVALID_PASSWORD_TRY_ANOTHER_ONE,
  INVALID_PASSWORD,
  GOOGLE_FIELDS,
  HELLO_S,
  VERIFICATION_CODE,
  GO_BACK,
  LAST_REGISTRATION_STEP,
  THINK_OF_A_PASSWORD,
  PASSWORD,
  CONFIRM_PASSWORD,
  ENTER_THE_CONFIRMATION_CODE,
  YOU_CAN_RESUBMIT_VIA,
  RESEND_CODE,
  SECONDS_S,
  RESEND_CODE_BUTTON,
  DONE,
  PLEASE_WAIT_ACCOUNT_VERIFICATION,
  COMPLETE_THE_SURVEY,
  REGISTRATION_REJECTED,
  DATA_NOT_FOUND,
  INCORRECT_REQUEST,
  USER_IS_NOT_REGISTERED,
  INTERNAL_ERROR,
  SERVER_UNAVAILABLE,
  REGISTRATION_REQUEST,
  HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM,
  WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE,
  HOW_MANY_SUBSCRIBERS,
  DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA,
  ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR,
  SEND_APPLICATION,
  APPLICATION_SUCCESSFULLY_CREATED,
  CONTINUE,
  REMEMBER_ME,
  INVALID_INPUT_FORMAT,
  EMAIL_OR_PHONE,
  FORGOT_PASSWORD, IF_YOU_HAVE_NOT_ACCOUNT, REGISTER,
} from '@main/i18n/aliases';

export default {
  [COPY_LINK]:'Копировать ссылку',
  [INVALID_INPUT_FORMAT]:'Неверный формат ввода',
  [EMAIL_OR_PHONE]:'E-mail или Телефон',
  [FORGOT_PASSWORD]:'Забыли пароль?',
  [IF_YOU_HAVE_NOT_ACCOUNT]:'Если у Вас нет аккаунта, ',
  [REGISTER]:'Зарегистрируйтесь',
  [CONTINUE]:'Продолжить',
  [RESEND_CODE_BUTTON]:'Выслать ещё раз',
  [DONE]:'Готово',
  [PLEASE_WAIT_ACCOUNT_VERIFICATION]:'Вы прошли опрос, пожалуйста, подождите завершения проверки вашего аккаунта.',
  [COMPLETE_THE_SURVEY]:'Ваш аккаунт находится на модерации. Для того чтобы пройти модерацию, пожалуйста, пройдите опрос',
  [REGISTRATION_REJECTED]:'Ваша регистрация отклонена.',
  [DATA_NOT_FOUND]:'Данные не найдены',
  [INCORRECT_REQUEST]:'Запрос был отправлен некорректно',
  [USER_IS_NOT_REGISTERED]:'Пользователь не зарегистрирован',
  [INTERNAL_ERROR]:'Внутренняя ошибка сервера. Пожалуйста, обратитесь в поддержку.',
  [SERVER_UNAVAILABLE]:'Сервер временно не отвечает. Пожалуйста, повторите попытку позднее',
  [REGISTRATION_REQUEST]:'Заявка на регистрацию',
  [HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM]:'Откуда Вы узнали о Linkstream?',
  [WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE]:'Какой вид трафика Вы используете? (предоставьте ссылку на сайт/страницу VK/telegram)',
  [HOW_MANY_SUBSCRIBERS]:'Сколько у вас подписчиков (аудитория)?',
  [DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA]:'Есть ли опыт оказания услуг через CPA сети/платформы?',
  [ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR]:'Оформлены ли Вы как индивидуальный предприниматель/самозанятый?',
  [SEND_APPLICATION]:'Отправить заявку',
  [APPLICATION_SUCCESSFULLY_CREATED]:'Ваша заявка успешно создана',
  [YOU_CAN_RESUBMIT_VIA]:'Повторную отправку можно выполнить через',
  [RESEND_CODE]:'Если код не был доставлен, мы можем отправить его повторно',
  [SECONDS_S]:'с',
  [HAVE_USER_WHIS_PHONE]:'Пользователь с этим номером телефона уже зарегистрирован',
  [ENTER]:'Войдите',
  [NAME_FIELD]:'Имя',
  [NAME_PLACEHOLDER]:'Алексей',
  [PHONE_NUMBER]:'Номер телефона',
  [INVALID_PASSWORD_TRY_ANOTHER_ONE]:'Неверно введенный пароль. Попробуйте другой',
  [INVALID_PASSWORD]:'Неверно введенный пароль',
  [GOOGLE_FIELDS]:'Мы использовали ваши данные с Google аккаунта. Заполните пустые поля если такие имеются.',
  [HAVE_ACCOUNT_Q]:'Есть аккаунт?',
  [HELLO_S]:'здравствуйте',
  [VERIFICATION_CODE]:'Код подтверждения',
  [GO_BACK]:'Вернуться назад',
  [LAST_REGISTRATION_STEP]:'Остался 1 шаг, чтобы начать работу — придумайте пароль',
  [THINK_OF_A_PASSWORD]:'Придумайте пароль',
  [PASSWORD]:'Пароль',
  [CONFIRM_PASSWORD]:'Подтвердите пароль',
  [ENTER_THE_CONFIRMATION_CODE]:'Введи код подтверждения высланный на номер',
  [DOES_NOT_HAVE_CONNECTED_OFFERS]:'Упс, вы не подключили ни одного оффера',
  [DOES_NOT_HAVE_CONNECTED_OFFERS_DESC]:'Для подключения офферов перейдите на страницу «Все офферы», там вы можете выбрать интересующее вас предложение. После подключения оффера,\nон появится на данной странице, где вы сможете управлять им.',
  [GO_TO_OFFER_PAGE]:'Перейти на страницу офферы',
  [EMPTY]:'Пусто',
  [EMPTY_FAVORITE_OFFERS]:'Вы можете добавить офферы в избранное из списка, нажав\nна звёздочку в правом верхнем углу карточки оффера.',
  [DOES_NOT_HAVE_HOLD]:'Холд отсутствует',
  [DOES_NOT_HAVE_HOLD_DESC]:'когда вы начнёте лить трафик, Вы сможете отслеживать когда и какие суммы будут в холде,выходить из него и доступны Вам для вывода средств.',
  [YOU_HAVE_NOT_MADE_ANY_PAYMENTS]:'вы не совершали ни одной выплаты',
  [YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC]:'После создании выплаты она попадает на данную страницу, где вы можете отслеживать её статус',
  [ORDER_PAYMENT]:'Заказать выплату',
  [LINK_COPIED]:'Ссылка скопирована',
  [UTM_MARKS]:'Utm метки',
  [UTM_MARKS_CREATE_DESC]:'Вы можете указать «Utm метки» и забрать сгенерированную ссылку здесь или  указать метки напрямую в адресной строке.',
  [SELECTED]:'Выбран',
  [SELECTED_1]:'Выбраны',
  [SELECTED_2]:'Выбрано',
  [SELECTED_3]:'Выбрана',
  [BY_PLATFORM]:'По площадке',
  [BY_UTM_MARKS]:'По utm меткам',
  [MARK_L]:'метка',
  [MARK_L_1]:'метки',
  [MARKS_L]:'меток',
  [PLATFORM_L]:'площадка',
  [PLATFORM_L_1]:'площадок',
  [PLATFORMS_L]:'площадки',
  [BY_OFFER]:'По офферу',
  [OFFERS_1]:'офферов',
  [EXIT_DATES_FROM_HOLD]:'Даты выхода из холда',
  [NUMBER_OF_CONVERSIONS]:'Количество конверсий',
  [HOLD_REMOVAL_SCHEDULE]:'График снятия холда',
  [HOLD_REMOVAL_SCHEDULE_]:'Данный график показывает Вам, какая сумма денег будет доступна для вывода, какая будет в холде и какая выйдет из холда.',
  [CAME_OUT_OF_HOLD]:'Вышло из холда',
  [ERROR_WHILE_GETTING_LIST_OF_OFFERS]:'Ошибка при получении списка офферов',
  [ID]:'ID',
  [COMPLETED_ALL_ACTIONS]:'Cовершившие все действия',
  [CONVERSIONS]:'Конверсии',
  [REVENUE_PER_CONVERSION]:'Доход с одной конверсии',
  [REVENUE]:'Доход',
  [NUMBER_OF_FIRST_EVENT_CONVERSIONS]:'Количество конверсий первого ивента',
  [FIRST_EVENT_CONVERSION_PERCENTAGE]:'Процент конверсий первого ивента',
  [BY_HOURS]:'По часам',
  [BY_DAY]:'По дням',
  [BY_MONTH]:'По месяцам',
  [BY_YEAR]:'По годам',
  [BY_OFFERS]:'По офферам',
  [BY_CHANNELS]:'По каналам',
  [BY_CHANNEL]:'По каналу',
  [CLICKS]:'Клики',
  [APPROVED]:'Подтверждённые',
  [SUBSCRIBERS]:'Подписчики',
  [SUBSCRIBERS_DESC]:'Подписчики',
  [BANNED]:'Отписки',
  [DATE_OF_CREATION]:'Дата создания',
  [SUM]:'Сумма',
  [PAYOUT_METHOD]:'Метод выплат',
  [PAYMENT_TERM]:'Срок выплаты',
  [COMMISSION_AMOUNT]:'Размер комиссии',
  [WORKING_DAY]:'рабочий день',
  [WORKING_DAYS]:'рабочих дня',
  [WORKING_DAYS_2]:'рабочих дней',
  [ZALEYCASH]:'ZaleyCash',
  [GETUNIQ]:'GetUniq',
  [WEBMONEY_WMZ]:'Webmoney (WMZ)',
  [YANDEX_MONEY]:'Яндекс деньги',
  [SOLAR_STAFF]:'Solar-staff (Банковская карта)',
  [CAPITALIST]:'Capitalist (Рублёвая платёжная система)',
  [GOOGLE_ADWORDS]:'Google adwords (РКС)',
  [MYTARGET]:'Mytarget (РКС)',
  [YANDEX_DIRECT]:'YandexDirect (РКС)',
  [BANK_CARD]:'Банковская карта',
  [BANK_TRANSFER]:'Банковский перевод (Только для юридических лиц)',
  [BANK_TRANSFER_WITH_EDI]:'Банковский перевод (С подключением ЭДО)',
  [BANK_TRANSFER_SELF_EMPLOYED]:'Банковский перевод (Самозанятые)',
  [IN_PROCESSING]:'В обработке',
  [COMPLETED]:'Завершенные',
  [REJECTED]:'Отклоненные',
  [PAYMENT_HISTORY]:'История выплат',
  [REMOVING_A_HOLD]:'Снятие холда',
  [TERMS_AND_PROCEDURE_OF_PAYMENTS]:'Сроки и порядок выплат',
  [TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC]:'Срок указан ориентировочно, при условии быстрого обмена документами. Оплата производится в течение 3 рабочих дней с момента предоставления вебмастером подписанных оригиналов документов.',
  [WAS_CREATED]:'Созданные',
  [ERROR_DURING_FETCH_BALANCE]:'Ошибка получения баланса',
  [NEW_REPORT]:'Новый отчёт',
  [SELECT_DATES]:'Выбрать даты',
  [RESET_ALL]:'Сбросить всё',
  [APPLY_FILTERS]:'Применить фильтры',
  [FILTERS]:'Фильтры',
  [CHOOSE_THE_FILTERS_]:'Выбирайте нужные фильтры и они появятся в этой области',
  [TOUCH_REPORT]:'Отчёт по касаниям',
  [DAILY_REPORT]:'Отчёт по дням',
  [CONNECT_OFFER]:'Подключить оффер',
  [REGISTRATION]:'Зарегистрироваться',
  [OFFER_DESC]:'Описание оффера',
  [MONETIZATION_OPTIONS]:'Варианты монетизации',
  [AVAILABLE_MESSENGERS]:'Доступные мессенджеры',
  [EVENTS]:'Ивенты',
  [CLICK_PER_LINK_DESC]:'Среднее количество кликов на 1 ссылку',
  [PAYOUTS]:'Выплаты',
  [BACK]:'Назад',
  [ERROR]:'Ошибка',
  [SEARCH]:'Поиск',
  [PLACE]:'Площадка',
  [PLACE_ACTIVE]: 'Площадка активна',
  [PLACE_STOPPED]: 'Площадка остановлена',
  [ACTIVATION_ERROR]: 'Ошибка запуска',
  [STOPPED_ERROR]: 'Ошибка остановки',
  [WANT_DELETE_PACE]: 'Вы уверены, что хотите удалить площадку?',
  [CANCEL]: 'Отмена',
  [APPROVE]: 'Подтвердить',
  [ATTRITION]: 'Отток',
  [ON_THIS_PAGE_YOU_CAN_SEE_THE_TERMS_]:'На данной странице Вы можете посмотреть сроки,порядок, комиссии по выплатам, и подобрать для себя лучший вариант.',
  [ALL_OFFERS]:'Все офферы',
  [MY_OFFERS]:'Мои офферы',
  [EXIT]:'Выход',
  [PENDING]:'В ожидании',
  [REVENUE_SHARE_DESC]:'Модель партнерской программы, по которой вебмастер получает процент от принесенной прибыли. Короткие холды. Данная модель будет приносить доход еще минимум несколько месяцев. Никаких лимитов на выплаты. Количество выплат зависит напрямую от вашего качественного трафика.',
  [REVENUE_SHARE]:'Доля выручки',
  [COST_PER_ACTION_DESC]:'Оплата за какое-либо указанное в оффере действие, приведенного человека в диалог. Быстрые деньги, но большие холды. Есть лимиты на выплаты в сутки / неделю.\nВозможность отклонения конверсий. Не всегда быстрые согласования с рекламодателями.',
  [COST_PER_ACTION]:'Выплата за действие',
  [OFFER_INFO]:'Информация об оффере',
  [DELETE_ERROR]:'Ошибка удаления',
  [INCOME_PER_DIALOG_DESCRIPTION]:'Сумма выплат за 1 оплачиваемую цель',
  [INCOME_PER_DIALOG]:'Доход с диалога',
  [LTV_DESC]:'',
  [TOTAL_INCOME]:'Общее кол-во дохода',
  [LTV]:'ltv',
  [THE_AUDIENCE_DESC]:'Объем активных подписчиков, который накоплен у вас в боте (исключая забаненных и неактивных юзеров)',
  [THE_AUDIENCE]:'Аудитория',
  [CONNECTED_MESSENGERS_TO_OFFERS]:'Подключенные мессенджеры к офферу',
  [CONNECTED_CHANNELS]:'Подключенные каналы',
  [INCOME_PER_DAY]:'Доход за день',
  [AVAILABLE]:'Доступно',
  [YIELD_CHART]:'График доходности',
  [ORDER_A_PAYOUT]:'Заказать выплату',
  [MONEY_THAT_IS_CURRENTLY_NOT_AVAILABLE_FOR_WITHDRAWAL]:'Деньги, которые в текущий момент недоступны для вывода.',
  [ON_THIS_PAGE_YOU_CAN_WITHDRAW_YOUR_FUNDS]:'На данной странице Вы можете выводить свои средства, просматривать историю выплат, сравнивать доходность и т.д.',
  [WELCOME_TO_FINANCE]:', добро пожаловать в Финансы',
  [PAYMENTS_YOU_ORDERED]:'Выплаты, которые вы заказали',
  [MONEY_THAT_IS_CURRENTLY_AVAILABLE_FOR_WITHDRAWAL]:'Деньги, которые в текущий момент доступны для вывода.',
  [ERROR_LOADING_PAYMENT_METHODS]:'Ошибка загрузки способов выплат',
  [THE_AMOUNT_OF_MONEY]:'Сумма доступных для вывода денег и денег в холде.',
  [BALANCE]: 'Баланс',
  [REPORTS]: 'Отчёты',
  [GOALS_AND_ACTIONS]: 'Цели и действия',
  [DESCRIPTION]: 'Описание',
  [AVAILABLE_CHANNELS]: 'Доступные каналы для подключения',
  [HOLD]: 'Холд',
  [ON_HOLD]: 'В холде',
  [GEOGRAPHY]: 'География',
  [UNWANTED_CITIES]: 'Нежелательные города',
  [PREFERRED_CITIES]: 'Предпочтительные города',
  [BY_INTERACTION]: 'Отчет по касаниям',
  [REPORT_BY_DAY]: 'Отчет по дням',
  [GROUPING_BY_HOURS]: 'Группировка по часам',
  [GROUPING_BY_DAYS]: 'Группировка по дням',
  [GROUPING_BY_MONTHS]: 'Группировка по месяцам',
  [GROUPING_BY_YEARS]: 'Группировка по годам',
  [HOUR]: 'Час',
  [DAY]: 'День',
  [MONTH]: 'Месяц',
  [YEAR]: 'Год',
  [CUSTOM_DATES]: 'Выбранные даты',
  [TODAY]: 'Сегодня',
  [NEW_USERS]: 'Новые пользователи',
  [ACTIVE]: 'Активные',
  [ACTIVE_USERS]: 'Активные пользователи',
  [ACTIONS]: 'Действия',
  [ACTIONS_COUNT]: 'Количество действий в диалогах',
  [CHURN]: 'Отписки',
  [CHURN_COUNT]: 'Количество отписок',
  [LAST_7_DAYS]: 'Последние 7 дней',
  [LAST_14_DAYS]: 'Последние 14 дней',
  [LAST_30_DAYS]: 'Последние 30 дней',
  [CURRENT_MONTH]: 'Текущий месяц',
  [LAST_MONTH]: 'Предыдущий месяц',
  [ADD_FILTER]: 'Добавить фильтр',
  [ADD_GROUPING]: 'Добавить группировку',
  [ADD_COLUMNS]: 'Добавить колонки',
  [DATE]: 'Дата',
  [USERS]: 'Пользователи',
  [APPROVED_CONVERSIONS]: 'Подтвержденные цели',
  [HOLD_CONVERSIONS]: 'Ожидающие цели',
  [REJECTED_CONVERSIONS]: 'Отклоненные цели',
  [ALL_GOALS]: 'Все цели',
  [OFFER]: 'Оффер',
  [OFFER_L]: 'оффер',
  [CHANNEL]: 'Канал',
  [CHANNEL_L]: 'канал',
  [CHANNEL_L_1]: 'канала',
  [CHANNEL_L_2]: 'каналов',
  [PLATFORM]: 'Площадка',
  [OFFERS]: 'Офферы',
  [OFFERS_L]: 'офферы',
  [OLDEST_FIRST]: 'Сначала старые',
  [NEWEST_FIRST]: 'Сначала новые',
  [COUNTRY]: 'Страна',
  [CZECH_REPUBLIC]: 'Чехия',
  [UKRAINE]: 'Украина',
  [SPAIN]: 'Испания',
  [POLAND]: 'Польша',
  [USA]: 'США',
  [KAZAKHSTAN]: 'Казахстан',
  [RUSSIA]: 'Россия',
  [ITALY]: 'Италия',
  [GERMANY]: 'Германия',
  [RESET]: 'Сбросить',
  [DIALOGS_COUNT]: 'Количество диалогов',
  [LEADS]: 'Лиды',
  [CR_CLICKED_LINKS]: 'Процент перешедших по ссылкам',
  [STATISTICS]: 'Статистика',
  [EARNINGS_PER_CONVERSION]: 'Доход с конверсии',
  [CR_GIVEN_LINKS]: 'Процент выданных ссылок',
  [AUDIENCE_CHURN]: 'Потеря аудитории',
  [EARNINGS_PER_DIALOG]: 'Доход с диалога',
  [PAYMENTS_AMOUNT]: 'Сумма выплат',
  [RATING_NOT_YET_AVAILABLE]: 'Идет подсчет рейтинга',
  [DIALOG_COST]: 'Стоимость диалога',
  [CONNECT]: 'Подключить',
  [CHANNELS]: 'Каналы',
  [NAME]: 'Название',
  [SETTINGS]: 'Настройки',
  [DIALOGS]: 'Диалоги',
  [LINKS]: 'Ссылки',
  [ANALYTICS]: 'Статистика',
  [REMOVE]: 'Удалить',
  [FINANCES]: 'Финансы',
  [INCOME]: 'Доход',
  [INCOME_DESC]: 'Доход',
  [OFFER_STAT_INCOME_DESC]: 'Сумма выплаченная веб мастерам за последние 30 дней',
  [TOTAL]: 'Всего',
  [TOTAL_L]: 'всего',
  [CITIES]: 'Города',
  [ACTION_TYPE]: 'Тип действия',
  [NO_DATA]: 'Нет данных',
  [NO_DATA_BY_FILTERS]: 'Мы не смогли найти данные для отчёта по выбранным фильтрам.',
  [NO_CONNECTED_CHANNELS]: 'Вы не подключили ни одного канала',
  [NO_CONNECTED_CHANNELS_DESC]: 'Для подключения каналов перейдите на страницу «Все офферы», там вы можете выбрать интересующее вас предложение. После подключения канала к выбранному офферу,\nон появится на данной странице, где вы сможете управлять им.',
  [ALL_CHANNELS]: 'Все каналы',
  [ADD_CHANNEL]: 'Добавить канал',
  [TRACKING_LINK]: 'Трекинговая ссылка',
  [TRAFFIC_TYPE]: 'Тип трафика',
  [SOURCE]: 'Источник',
  [CAMPAIGN_NAME]: 'Название кампании',
  [EXTRA_FIELD]: 'Доп. поле',
  [MAIN_PARAMETERS]: 'Основные параметры',
  [ADDITIONAL_PARAMETERS]: 'Дополнительные параметры',
  [DIRECT_LINK]: 'Прямая ссылка',
  [HTML_CODE]: 'Код для размещения на сайте',
  [CLICK_TO_COPY]: 'Нажмите, чтобы скопировать',
  [COPIED]: 'Скопировано',
  [ORDER_A_PAYMENT]: 'Заказать выплату',
  [TOTAL_ON_ACCOUNT]: 'Всего на счету',
  [AVAILABLE_FOR_WITHDRAWAL]: 'Доступно для вывода',
  [PAYOUT_HISTORY]: 'История выплат',
  [DOWNLOAD_REPORT]: 'Сгенерировать отчет',
  [AMOUNT]: 'Сумма',
  [STATUS]: 'Статус',
  [ESTIMATE_DATE]: 'Прогноз выплаты',
  [BILL]: 'Чек',
  [BILLING_METHOD]: 'Метод выплат',
  [COMMENT]: 'Комметарий',
  [ALL]: 'Все',
  [FAVORITES]: 'Избранное',
  [LOGO]: 'Лого',
  [MORE]: 'Подробнее',
  [PLACE_DELETED]: 'Площадка удалена',
  [CREATED]: 'Создана',
  [IN_PROGRESS]: 'В работе',
  [CLOSED]: 'Закрыта',
  [NEW_REQUEST]: 'Новая заявка',
  [ENTER_THE_AMOUNT_TO_WITHDRAW]: 'Введите сумму для вывода',
  [DOWNLOAD]: 'Скачать',
  [REQUEST]: 'Заявка',
  [SWITCH_PROFILE]: 'Сменить аккаунт',
  [YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED]: 'Для дальнейшей работы вам нужно подключить ваш аккаунт Facebook к нашей системе',
  [YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED]: 'Для дальнейшей работы вам нужно подключить ваш аккаунт VK к нашей системе',
  [GROUPS_VK]: 'Группы ВК',
  [CHOOSE_THE_RIGHT_MESSENGER]: 'Выберите нужный мессенджер',
  [CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL]: 'Выберите способ монетизации для этого канала ',
  [CONNECT_CHANNEL]: 'Подключить канал',
  [YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED]: 'Ваш канал успешно подключен',
  [ON_THE_CHANNELS_PAGE_]: 'На странице каналы, вы сможете\nрегулировать настройки у групп более детально',
  [GO_TO_CHANNELS_PAGE]: 'Перейти на страницу каналов',
  [CONNECT_ANOTHER_CHANNEL]: 'Подключить еще канал',
  [CONNECTING_CHANNELS]: 'Подключение каналов',
  [CONVERSION]: 'Конверсия',
  [CONVERSION]: 'Конверсия',
  [OFFER_STAT_CONVERSION_DESC]: 'Процент конверсий это отношение числа посетителей сайта, выполнивших на нём какие-либо целевые действия за последние 30 дней.',
  [PAGES_FACEBOOK]: 'Страницы Facebook',
  [CONNECTING_TELEGRAM_BOT]: 'Подключаем Telegram бота',
  [CONNECTING_VIBER_BOT]: 'Подключаем Viber бота',
  [TOKEN]: 'Токен',
  [AUTHORIZATION]: 'Авторизация',
  [SIGN_IN]: 'Войти',
  [REMEMBER_ME]: 'Запомнить меня',
  [WHICH_CHANNEL_TO_CONNECT]: 'Какой канал подключаем?',
  [PAUSE]: 'Приостановить',
  [START]: 'Запустить',
  [TOOLS]: 'Инструменты',
  [MANAGE]: 'Управление',
  [OFFER_CONNECT_TOOLTIP]: 'Выберите канал для подключения бота',
  [CHANNELS_ADD_BUTTON_TOOLTIP]: 'Выберите оффер для подключения площадки',
  [CHANNELS_EXPANSION_LIST_TOOLTIP]: 'Отфильтровать подключенные площадки по каналу',
  [CHANNELS_DIALOGS_TOOLTIP]: 'Показать историю диалогов',
  [CHANNELS_LINKS_TOOLTIP]: 'Настройки трекинга',
  [CHANNELS_ANALYTICS_TOOLTIP]: 'Показать детальный отчет по площадке',
  [APPEARANCE]: 'Внешний вид',
};
